import { provide } from '@lit/context';
import { errorDialogContext, ErrorDialogController } from 'context/error-dialog-context';
import { css, html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from 'pli/styles';
import { registerContextProviders } from './register-context-providers';
import { consume } from '@lit/context';
import { AppContext, appContext } from 'context/app-context';
import { when } from 'lit/directives/when.js';
import { ToastController } from 'controllers/toast-controller';
import { toastServiceContext } from 'context/toast-service-context';

@customElement('page-layout')
class Layout extends LitElement {
    static styles = [
        styles.padding,
        styles.grid,
        css`
            :host {
                --sidebar-width: 7rem;
                --main-content-bottom-padding: var(--sidebar-width);
                --main-content-min-width: 72rem;
            }
            .sidebar {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: var(--sidebar-width);
            }
            main {
                padding-left: var(--sidebar-width);
                min-height: 100vh;
                min-width: var(--main-content-min-width);
            }

            .main-content {
                padding: var(--size-1-5);
                padding-bottom: var(--main-content-bottom-padding);
            }
        `,
    ];

    @provide({ context: errorDialogContext })
    errorDialog = new ErrorDialogController(this);

    @provide({ context: toastServiceContext })
    toastService = new ToastController(this);

    @consume({ context: appContext })
    appContext: AppContext;

    connectedCallback() {
        super.connectedCallback();
        registerContextProviders(this.appContext);
    }

    get hasContext(): boolean {
        return Boolean(this.appContext);
    }

    protected render(): unknown {
        return when(
            this.hasContext,
            () =>
                html`<main>
                    <div class="sidebar">
                        <slot name="sidebar"></slot>
                    </div>
                    <div class="main-content">
                        ${this.toastService.render()}
                        <slot></slot>
                    </div>
                    ${this.errorDialog.renderOnError()}
                </main>`,
        );
    }
}
