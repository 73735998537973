import { formatNumber } from 'utils/number-format';

export class NumbersValueConverter {
    toView(value) {
        if (value === undefined || value === null) {
            return;
        }

        return formatNumber(value.toString());
    }
}
