import { css } from 'lit';

export const baseStyles = css`
    * {
        margin: 0;
        padding: 0;
        min-width: 0;
        box-sizing: border-box;
        text-decoration: none;
        font-family: inherit;
    }

    a {
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }
`;

export const flexStyles = css`
    .flex {
        display: flex;
    }
    .flex-1 {
        flex: 1;
    }
    .flex-2 {
        flex: 2;
    }
    .flex-vertical {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .items-center {
        align-items: center;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-end {
        justify-content: flex-end;
    }
`;

export const gridStyles = css`
    .grid {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    .col-span-auto {
        grid-column: auto;
    }

    .col-span-1 {
        grid-column: span 1 / span 1;
    }
    .col-span-2 {
        grid-column: span 2 / span 2;
    }
    .col-span-3 {
        grid-column: span 3 / span 3;
    }
    .col-span-4 {
        grid-column: span 4 / span 4;
    }
    .col-span-5 {
        grid-column: span 5 / span 5;
    }
    .col-span-6 {
        grid-column: span 6 / span 6;
    }
    .col-span-7 {
        grid-column: span 7 / span 7;
    }
    .col-span-8 {
        grid-column: span 8 / span 8;
    }
    .col-span-9 {
        grid-column: span 9 / span 9;
    }
    .col-span-10 {
        grid-column: span 10 / span 10;
    }
    .col-span-11 {
        grid-column: span 11 / span 11;
    }
    .col-span-12 {
        grid-column: span 12 / span 12;
    }
    .col-start-1 {
        grid-column-start: 1;
    }
    .col-start-2 {
        grid-column-start: 2;
    }
    .col-start-3 {
        grid-column-start: 3;
    }
    .col-start-4 {
        grid-column-start: 4;
    }
    .col-start-5 {
        grid-column-start: 5;
    }
    .col-start-6 {
        grid-column-start: 6;
    }
    .col-start-7 {
        grid-column-start: 7;
    }
    .col-start-8 {
        grid-column-start: 8;
    }
    .col-start-9 {
        grid-column-start: 9;
    }
    .col-start-10 {
        grid-column-start: 10;
    }
    .col-start-11 {
        grid-column-start: 11;
    }
    .col-start-12 {
        grid-column-start: 12;
    }
    .col-span-full {
        grid-column: 1 / -1;
    }

    @media (min-width: 1600px) {
        .col-lg-span-auto {
            grid-column: auto;
        }
        .col-lg-span-1 {
            grid-column: span 1 / span 1;
        }
        .col-lg-span-2 {
            grid-column: span 2 / span 2;
        }
        .col-lg-span-3 {
            grid-column: span 3 / span 3;
        }
        .col-lg-span-4 {
            grid-column: span 4 / span 4;
        }
        .col-lg-span-5 {
            grid-column: span 5 / span 5;
        }
        .col-lg-span-6 {
            grid-column: span 6 / span 6;
        }
        .col-lg-span-7 {
            grid-column: span 7 / span 7;
        }
        .col-lg-span-8 {
            grid-column: span 8 / span 8;
        }
        .col-lg-span-9 {
            grid-column: span 9 / span 9;
        }
        .col-lg-span-10 {
            grid-column: span 10 / span 10;
        }
        .col-lg-span-11 {
            grid-column: span 11 / span 11;
        }
        .col-lg-span-12 {
            grid-column: span 12 / span 12;
        }
        .col-lg-span-full {
            grid-column: 1 / -1;
        }
        .col-lg-start-1 {
            grid-column-start: 1;
        }
        .col-lg-start-2 {
            grid-column-start: 2;
        }
        .col-lg-start-3 {
            grid-column-start: 3;
        }
        .col-lg-start-4 {
            grid-column-start: 4;
        }
        .col-lg-start-5 {
            grid-column-start: 5;
        }
        .col-lg-start-6 {
            grid-column-start: 6;
        }
        .col-lg-start-7 {
            grid-column-start: 7;
        }
        .col-lg-start-8 {
            grid-column-start: 8;
        }
        .col-lg-start-9 {
            grid-column-start: 9;
        }
        .col-lg-start-10 {
            grid-column-start: 10;
        }
        .col-lg-start-11 {
            grid-column-start: 11;
        }
        .col-lg-start-12 {
            grid-column-start: 12;
        }
    }

    .gap-1 {
        gap: var(--size-1);
    }

    .gap-2 {
        gap: var(--size-2);
    }

    .gap-3 {
        gap: var(--size-3);
    }

    .gap-4 {
        gap: var(--size-4);
    }

    .grid-vertical {
        display: grid;
    }

    .align-self-start {
        align-self: flex-start;
    }

    .align-self-center {
        align-self: center;
    }

    .align-self-end {
        align-self: flex-end;
    }

    .center {
        justify-content: center;
        align-items: center;
    }

    .center > * {
        margin: 0 auto;
    }

    .grid-horizontal {
        display: grid;
        grid-auto-columns: 1fr;
        grid-auto-flow: column;
    }
`;

export const statusStyles = css`
    .new {
        background-color: var(--color-non-photo-blue);
        color: var(--color-cerulean);
    }

    .opened {
        background-color: var(--color-pink);
        color: var(--color-murrey);
    }

    .closed {
        background-color: var(--color-onyx);
        color: var(--color-seasalt);
    }

    .false-positive {
        background-color: var(--color-peach);
        color: var(--color-browange);
    }

    .escalated {
        background-color: var(--color-lime-green);
        color: var(--color-dark-moss-green);
    }

    .investigating {
        background-color: var(--color-mauve);
        color: var(--color-indigo);
    }

    .resolved {
        background-color: var(--color-light-gray);
        color: var(--color-space-gray);
    }

    .reporting {
        background-color: var(--color-mint-green);
        color: var(--color-brunswick-green);
    }

    .reported {
        background-color: var(--color-jordy-blue);
        color: var(--color-marian-blue);
    }

    .in-progress {
        background-color: var(--color-light-gray);
        color: var(--color-space-gray);
    }

    .cancelled {
        background-color: var(--color-pink);
        color: var(--color-murrey);
    }

    .live {
        background-color: var(--color-jungle-green);
        color: var(--color-white);
    }

    .editing {
        background-color: var(--color-tang-blue);
        color: var(--color-white);
    }

    .preview {
        background-color: var(--color-periwinkle);
        color: var(--color-delft-blue);
    }

    .paused {
        background-color: var(--color-hollywood-cerise);
        color: var(--color-white);
    }
`;
