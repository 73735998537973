import { AppContext } from 'context/app-context';
import { createClientProvider } from 'context/client-context';
import { createCurrentUserContextProvider } from 'context/current-user-context';
import { createDialogServiceProvider } from 'context/dialog-service-context';
import { createRouterProvider } from 'context/router-context';

export function registerContextProviders(appContext: AppContext) {
    const { httpClient, router, currentUser, dialogService } = appContext;

    createClientProvider(httpClient);
    createRouterProvider(router);

    createCurrentUserContextProvider(currentUser);

    createDialogServiceProvider(dialogService);
}
