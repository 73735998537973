import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import environment from './environment';
import oidcConfig from './open-id-connect-configuration-auth0';
import Hotjar from '@hotjar/browser';
const config2 = require('./config');
import 'font-awesome/css/font-awesome.css';
import { CustomDialogRenderer } from 'customDialogRenderer';

export function configure(aurelia: Aurelia): void {
    aurelia.use
        .standardConfiguration()
        // .developmentLogging()
        .plugin(PLATFORM.moduleName('aurelia-validation'))
        .feature(PLATFORM.moduleName('resources/index'))
        .plugin(PLATFORM.moduleName('aurelia-dialog'), (config) => {
            config.useDefaults();
            config.useCSS('');
            config.useRenderer(CustomDialogRenderer);
        })
        .plugin(PLATFORM.moduleName('aurelia-open-id-connect'), () => oidcConfig);

    // aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');


    if (environment.testing) {
        aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
    }

    aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
