import { LitElement, ReactiveControllerHost } from 'lit';
import { Ref } from 'lit/directives/ref';

type InputLikeElement = Ref<HTMLInputElement | HTMLTextAreaElement>;

export class PliInputController {
    _value = '';
    host: ReactiveControllerHost & LitElement;
    ref: InputLikeElement;

    constructor(host: ReactiveControllerHost & LitElement, ref: InputLikeElement) {
        this.host = host;
        host.addController(this);
        this.ref = ref;
    }

    async focusElement() {
        if (!this.ref.value) {
            return;
        }

        await this.host.updateComplete;

        this.ref.value.focus();
    }

    hostConnected() {}

    inputHandler = (e: KeyboardEvent) => {
        this._value = (e.currentTarget as HTMLInputElement).value;
        const event: PliInputChangeEvent = new CustomEvent('change', {
            composed: true,
            detail: { value: this._value },
        });
        this.host.dispatchEvent(event);
    };
}

export type PliInputChangeEvent = CustomEvent<{ value: string }>;
