import { OpenIdConnectConfiguration } from 'aurelia-open-id-connect';
import { Log, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
const config = require('./config');

// alert(`config: ${JSON.stringify(config)}`)

export default {
    loginRedirectRoute: '/private',
    logoutRedirectRoute: '/index',
    unauthorizedRedirectRoute: '/unauthorized',
    logLevel: 0,
    //   logLevel: Log.DEBUG,
    userManagerSettings: {
        // The number of seconds in advance of access token expiry
        // to raise the access token expiring event.
        accessTokenExpiringNotificationTime: 60,
        // accessTokenExpiringNotificationTime: 3500,
        // Either host your own OpenID Provider or select a certified authority
        // from the list http://openid.net/certification/
        authority: config.authority,
        // authority: "http://localhost:5000",
        automaticSilentRenew: true,
        // The interval in milliseconds between checking the user's session.
        monitorSession: true,
        checkSessionInterval: 2000,
        // The client or application ID that the authority issues.
        client_id: config.client_id,
        // client_id: "local_secure",
        filterProtocolClaims: true,
        loadUserInfo: true,
        post_logout_redirect_uri: `${config.appHost}/signout-oidc`,
        redirect_uri: `${config.appHost}/signin-oidc`,
        scope: 'openid profile',
        response_type: 'code',
        // scope: "openid email customer_dashboard",
        // number of millisecods to wait for the authorization
        // server to response to silent renew request
        silentRequestTimeout: 10000,
        silent_redirect_uri: `${config.appHost}/signin-oidc`,
        userStore: new WebStorageStateStore({
            prefix: 'oidc',
            store: window.localStorage,
        }),
    } as UserManagerSettings,
} as OpenIdConnectConfiguration;
