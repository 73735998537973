export class Persons {
    persons: Person[] = [
        new Person('d042b649-ae7b-484b-9593-c189ff4119cd', 'Ebba', 'Busch', true, false, false, false),
        new Person('cc2152e2-cd3d-4ed6-a2c0-99a5d9824af3', 'Osama', 'bin Laden', false, true, true, true),
    ];
}

class Person {
    constructor(
        public id: string,
        public firstName: string,
        public lastName: string,
        public isPep: boolean,
        public isRca: boolean,
        public isSanction: boolean,
        public isSip: boolean,
    ) {}
}
